import React, { useEffect, useState } from "react";
import { choiceMessages, sort } from "../env/constants";
import { getUserLibrary } from "../helpers/api";
import { getToken, getTokenFromRefresh, login } from "../helpers/login";
import AlbumTile from "./AlbumTile";

const Home = () => {
  const [token, setToken] = useState();
  const [albums, setAlbums] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [left, setLeft] = useState();
  const [right, setRight] = useState();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [lockWinner, setLockWinner] = useState(true);
  // const [rowSize, setRowSize] = useState(4);

  // const [genres, setGenres] = useState([]);
  // const [selectedGenre, setSelectedGenre] = useState();

  // const [search, setSearch] = useState("");

  const asyncProgressBar = async () => {
    for (let i = 0; i < 100; i++) {
      await new Promise((r) => setTimeout(r, 10));
      setProgress(i);
    }
  };

  const getAlbums = async () => {
    setLoading(true);
    let data;
    let len;
    // animate progress bar to increase to 75%
    asyncProgressBar();
    data = await getUserLibrary();
    for (let i = 0; i < data.items.length; i++) {
      data.items[i] = data.items[i].album;
    }
    data.items.filter((album) => {
      return album !== undefined;
    });
    setAlbums(data.items);
    len = data.items.length;
    let l = Math.floor(Math.random() * len);
    setLeft(l);
    let r = Math.floor(Math.random() * len);
    while (l === r) {
      r = Math.floor(Math.random() * len);
    }
    setRight(r);
    setLoading(false);
    setProgress(0);
    setShowModal(true);
  };

  // For getting newly added albums in library
  const refreshAlbums = async () => {
    setLoading(true);
    // animate progress bar to increase to 75%
    asyncProgressBar();
    let data = await getUserLibrary();
    for (let i = 0; i < data.items.length; i++) {
      data.items[i] = data.items[i].album;
    }
    data.items.filter((album) => {
      return album !== undefined;
    });
    // Need to save existing scores
    let oldAlbums = albums;
    let newAlbums = data.items;
    let oldAlbumsMap = {};
    for (let i = 0; i < oldAlbums.length; i++) {
      oldAlbumsMap[oldAlbums[i].id] = oldAlbums[i];
    }
    for (let i = 0; i < newAlbums.length; i++) {
      if (oldAlbumsMap[newAlbums[i].id]) {
        newAlbums[i].points = oldAlbumsMap[newAlbums[i].id].points;
      }
    }
    setAlbums(newAlbums);
    setLoading(false);
    setProgress(0);
  };

  const winner = (index) => {
    let leftPoints = albums[left].points ? albums[left].points : 1000;
    let rightPoints = albums[right].points ? albums[right].points : 1000;
    if (index === left) {
      let newLeftPoints =
        leftPoints +
        32 * (1 - 1 / (1 + 10 ** ((rightPoints - leftPoints) / 400)));
      let newRightPoints =
        rightPoints +
        32 * (0 - 1 / (1 + 10 ** ((leftPoints - rightPoints) / 400)));
      albums[left].points = Math.floor(newLeftPoints);
      albums[right].points = Math.floor(newRightPoints);
      let newLeft = Math.floor(Math.random() * albums.length);
      while (newLeft === left || newLeft === right) {
        newLeft = Math.floor(Math.random() * albums.length);
      }
      let newRight = Math.floor(Math.random() * albums.length);
      while (newRight === left || newRight === right || newRight === newLeft) {
        newRight = Math.floor(Math.random() * albums.length);
      }
      setRight(newRight);
      if (!lockWinner) {
        setLeft(newLeft);
      }
    } else if (index === right) {
      let newLeftPoints =
        leftPoints +
        32 * (0 - 1 / (1 + 10 ** ((rightPoints - leftPoints) / 400)));
      let newRightPoints =
        rightPoints +
        32 * (1 - 1 / (1 + 10 ** ((leftPoints - rightPoints) / 400)));
      albums[left].points = Math.floor(newLeftPoints);
      albums[right].points = Math.floor(newRightPoints);
      let newRight = Math.floor(Math.random() * albums.length);
      while (newRight === left || newRight === right) {
        newRight = Math.floor(Math.random() * albums.length);
      }
      let newLeft = Math.floor(Math.random() * albums.length);
      while (newLeft === left || newLeft === right || newLeft === newRight) {
        newLeft = Math.floor(Math.random() * albums.length);
      }
      setLeft(newLeft);
      if (!lockWinner) {
        setRight(newRight);
      }
    }
    localStorage.setItem("albums", JSON.stringify(albums));
  };

  useEffect(() => {
    // Check if code in url
    const url = window.location.href;
    const hasCode = url.includes("?code=");
    // First check refresh token
    if (hasCode) {
      const newUrl = url.split("?code=");
      window.history.pushState({}, null, newUrl[0]);
      const code = newUrl[1];
      // Get token from spotify endpoint /api/token
      const token = async () => {
        let tokens = JSON.parse(await getToken(code)).token;
        if (!tokens.error) {
          localStorage.setItem("token", tokens.access_token);
          localStorage.setItem("refresh_token", tokens.refresh_token);
          setToken(tokens.access_token);
          // If albums are set already
          if (localStorage.getItem("albums")) {
            let a = JSON.parse(localStorage.getItem("albums"));
            setAlbums(a);
            let l = Math.floor(Math.random() * a.length);
            setLeft(l);
            let r = Math.floor(Math.random() * a.length);
            while (l === r && a.length > 1) {
              r = Math.floor(Math.random() * a.length);
            }
            setRight(r);
          }
        }
      };
      token();
    } else if (localStorage.getItem("refresh_token")) {
      console.log("REFRESHING");
      const refresh_token = localStorage.getItem("refresh_token");
      const token = async () => {
        let tokens = JSON.parse(await getTokenFromRefresh(refresh_token)).token;
        if (!tokens.error) {
          localStorage.setItem("token", tokens.access_token);
          localStorage.setItem("refresh_token", tokens.refresh_token);
          setToken(tokens.access_token);
          // If albums are set already
          if (localStorage.getItem("albums")) {
            let a = JSON.parse(localStorage.getItem("albums"));
            setAlbums(a);
            let l = Math.floor(Math.random() * a.length);
            setLeft(l);
            let r = Math.floor(Math.random() * a.length);
            while (l === r) {
              r = Math.floor(Math.random() * a.length);
            }
            setRight(r);
          }
        }
      };
      token();
    }
  }, []);

  return (
    <div>
      {/* Loading bar for importing library */}
      {loading && (
        <div className="col-12">
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      )}
      {!token ? (
        <div>
          <h1 className="m-5">
            Album Fight!
            <p>
              <a
                className="btn text-white"
                href="https://zachsilberstein.github.io"
              >
                by Zach
              </a>
            </p>
          </h1>
          <button className="btn btn-success" onClick={login}>
            Login with Spotify
          </button>
        </div>
      ) : (
        <div className="col-12 m-auto">
          <h1>
            {albums.length > 0 ? "Album Fight!" : "Welcome to Album Fight!"}
            <button
              className="btn btn-primary m-2"
              onClick={() => {
                setShowModal(true);
              }}
            >
              ?
            </button>
          </h1>
          {albums.length !== 0 ? (
            <span className="row w-75 m-auto">
              {/* Switch view */}
              <span className="col-6 col-md-3 p-2">
                <button
                  onClick={() => {
                    setAlbums(sort(albums, "points").reverse());
                    setShowAll(!showAll);
                  }}
                  className="btn btn-primary"
                >
                  {showAll ? "Let's Fight!" : "Leaderboards"}
                </button>
              </span>
              {/* Refresh Library */}
              <span className="col-6 col-md-3 p-2">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setProgress(0);
                    refreshAlbums();
                  }}
                >
                  Refresh Library
                </button>
              </span>
              {/* Clear Albums */}
              <span className="col-6 col-md-3 p-2">
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setAlbums([]);
                    localStorage.removeItem("albums");
                    setShowAll(false);
                  }}
                >
                  Clear Albums
                </button>
              </span>
              {/* Lock Winner */}
              <span className="col-6 col-md-3">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() => {
                      setLockWinner(!lockWinner);
                    }}
                    defaultChecked={lockWinner}
                  />
                  <span className="slider round"></span>
                </label>
                <p>Lock Winner</p>
              </span>
            </span>
          ) : (
            <div>
              <h3>Import some albums to get started!</h3>
              <div className="row justify-content-center">
                <div className="col-12">
                  <button
                    className="btn btn-primary my-2"
                    onClick={() => {
                      getAlbums();
                    }}
                  >
                    Import Library
                  </button>
                </div>
              </div>
            </div>
          )}
          {showAll && (
            <div className="row w-100 justify-content-center mx-auto">
              {albums.map(
                (album) =>
                  album.images.length > 0 && (
                    <div
                      className="col-6 col-md-2 align-self-top"
                      key={album.id}
                    >
                      <AlbumTile album={album} />
                    </div>
                  )
              )}
            </div>
          )}
          {!showAll && albums.length > 1 && (
            <div className="row justify-content-center h-100">
              <div className="col-6 col-md-3 my-2">
                <AlbumTile album={albums[left]} />
                <button
                  onClick={() => {
                    winner(left);
                  }}
                  className="btn btn-secondary w-100"
                >
                  {choiceMessages[left % choiceMessages.length]}
                </button>
              </div>
              <div className="col-6 col-md-3 my-2">
                <AlbumTile album={albums[right]} />
                <button
                  onClick={() => {
                    winner(right);
                  }}
                  className="btn btn-secondary w-100"
                >
                  {choiceMessages[right % choiceMessages.length]}
                </button>
              </div>
              <div className="col-12">
                <button
                  onClick={() => {
                    let l = Math.floor(Math.random() * albums.length);
                    setLeft(l);
                    let r = Math.floor(Math.random() * albums.length);
                    while (l === r) {
                      r = Math.floor(Math.random() * albums.length);
                    }
                    setRight(r);
                  }}
                  className="btn btn-primary"
                >
                  Skip
                </button>
              </div>
            </div>
          )}
          {showModal && (
            <div
              onClick={() => {
                setShowModal(false);
              }}
              style={{
                position: "fixed",
                left: "0",
                top: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: "1000",
              }}
            >
              <div
                style={{
                  position: "fixed",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "10px",
                  zIndex: "1000",
                  color: "black",
                }}
              >
                <div>
                  <div>
                    <div>
                      <h2>Welcome to Album Fight!</h2>
                    </div>
                    <div className="modal-body">
                      <p>
                        This is a simple app that allows you to compare the
                        albums within your library. You will be shown 2 albums
                        and you need to choose which one is better! Over time,
                        your entire library will be ranked from favorite to
                        least favorite based on your choices. Import your
                        library now to start!
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          setShowModal(false);
                        }}
                      >
                        Awesome!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Home;
