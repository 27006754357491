export var redirect_uri = window.location.origin;
export var baseAPI = "https://api.spotify.com/v1";
export var client_id = "95c76f0c0b0c4a97bbea742f6b8b0a85";
export const choiceMessages = [
  "This one rules!",
  "This for sure",
  "Yep, this one",
  "I pick this!",
  "I like this better",
  "Wow, great album!",
  "This is it.",
  "Winner winner!",
  "W",
  "Awesome.",
];
export const sort = (list, property) => {
  console.log("SORTING");
  console.log(
    list.sort((a, b) =>
      (a[property] ? a[property] : 1000) > (b[property] ? b[property] : 1000)
        ? 1
        : -1
    )
  );
  return list.sort((a, b) =>
    (a[property] ? a[property] : 1000) > (b[property] ? b[property] : 1000)
      ? 1
      : -1
  );
};
