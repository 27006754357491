import { redirect_uri, client_id } from "../env/constants";
import * as $ from "jquery";

export const login = () => {
  var scope =
    "user-read-private user-read-email user-library-read user-read-currently-playing user-read-playback-state user-modify-playback-state";
  window.location =
    "https://accounts.spotify.com/authorize?" +
    new URLSearchParams({
      response_type: "code",
      client_id: client_id,
      scope: scope,
      redirect_uri: redirect_uri,
      show_dialog: false,
    }).toString();
};

export async function getToken(code) {
  let netlifyFunc = "/.netlify/functions/login";
  let token = await $.ajax({
    type: "POST",
    url: `${netlifyFunc}?code=${code}&redirect=${redirect_uri}`,
    json: true,
    error: function (xhr, status, error) {
      console.log(xhr.responseText);
    },
    success: async (data) => {
      console.log(await data);
      return await data;
    },
  });
  return token;
}

export async function getTokenFromRefresh(refreshToken) {
  let netlifyFunc = "/.netlify/functions/refresh";
  let token = await $.ajax({
    type: "POST",
    url: `${netlifyFunc}?refresh=${refreshToken}&redirect=${redirect_uri}`,
    json: true,
    error: function (xhr, status, error) {
      console.log(xhr.responseText);
    },
    success: async (data) => {
      console.log(await data);
      return await data;
    },
  });
  return token;
}
