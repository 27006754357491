import React from "react";

function AlbumTile({ album }) {
  return (
    album && (
      <div className="card bg-dark" key={album.id}>
        {/* Album cover. Expand on hover */}
        <img
          src={album.images[0].url}
          className="card-img-top rounded-0"
          alt="..."
          title={album.name}
        />
        <div className="card-body my-4">
          {/* Truncate the album name if longer than 28 characters, also remove parenthesis after title. Show full album name on hover */}
          <h5 className="card-title" title={album.name}>
            {album.name.replace(/ \(.+\)/, "").length > 28
              ? album.name.replace(/ \(.+\)/, "").substring(0, 28) + "..."
              : album.name.replace(/ \(.+\)/, "")}
          </h5>
          {/* Truncate the artist name if longer than 25 characters, show full artist name on hover */}
          <p className="card-text" title={album.artists[0].name}>
            {album.artists[0].name.length > 25
              ? album.artists[0].name.substring(0, 25) + "..."
              : album.artists[0].name}
          </p>
          {/* Link to album on Spotify with Spotify logo */}
          <p>
            <a
              href={album.external_urls.spotify}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="Spotify_Logo_RGB_Green.png"
                alt="Spotify logo"
                style={{ width: "50%" }}
              />
            </a>
          </p>
          <p className="card-text">
            <small className="text-muted">
              Score: {album.points ? album.points : 1000}
            </small>
          </p>
        </div>
      </div>
    )
  );
}

export default AlbumTile;
