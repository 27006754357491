export async function getNewReleases() {
  let url = "https://api.spotify.com/v1/browse/new-releases?limit=50";
  return await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      return data;
    });
}

export async function getUserLibrary() {
  // Run in batches of 50
  let offset = 0;
  let baseurl = `https://api.spotify.com/v1/me/albums?limit=50&offset=`;
  let allAlbums = {
    items: [],
  };
  let done = false;
  while (!done) {
    let url = baseurl + offset;
    let d = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .catch(() => {
        return {
          items: [],
        };
      })
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    if (d.items.length === 0) {
      done = true;
    } else {
      offset += 50;
      allAlbums.items.push(...d.items);
    }
  }
  return allAlbums;
}

export async function search(term) {
  let url = `https://api.spotify.com/v1/search?q=${term}&type=album,artist,playlist,track`;
  return await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      return data;
    });
}

export async function readPlayback() {
  let url = "https://api.spotify.com/v1/me/player";
  return await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      return data;
    });
}

export async function playAlbum(albumid) {
  let url = "https://api.spotify.com/v1/me/player/play";
  return await fetch(url, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      context_uri: "spotify:album:" + albumid,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      return data;
    });
}

export async function getRecommendations(genre) {
  console.log(genre);
  let url =
    "https://api.spotify.com/v1/recommendations?limit=50&seed_genres=" + genre;
  return await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      return data;
    });
}

export async function getGenres() {
  let url = "https://api.spotify.com/v1/recommendations/available-genre-seeds";
  return await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      return data;
    });
}
